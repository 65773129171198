let defaults = {
    globalSpinnerText: 'core.loading'
};

import cookie from '@/client/utilities/cookie.js';

let userCookieName = 'talron-user-cookie';
let initialBatteryState;

let permissionActions = [
    'getBatteryDetails',
    'refreshBatteryWarranty',
    'replaceBatteryExternal',
    'replaceBatteryTalron',
];

let permissionMap     = {
    'default': {
        'getBatteryDetails'     : false,
        'refreshBatteryWarranty': false,
        'replaceBatteryExternal': false,
        'replaceBatteryTalron'  : false,
    },
    '1'      : {
        'getBatteryDetails'     : true,
        'refreshBatteryWarranty': true,
        'replaceBatteryExternal': false,
        'replaceBatteryTalron'  : false,
    },
    '2'      : {
        'getBatteryDetails'     : true,
        'refreshBatteryWarranty': false,
        'replaceBatteryExternal': false,
        'replaceBatteryTalron'  : false,
    },
    '3'      : {
        'getBatteryDetails'     : true,
        'refreshBatteryWarranty': true,
        'replaceBatteryExternal': true,
        'replaceBatteryTalron'  : true,
    },
    '4'      : {
        'getBatteryDetails'     : true,
        'refreshBatteryWarranty': false,
        'replaceBatteryExternal': false,
        'replaceBatteryTalron'  : true,
    },
    '5'      : {
        'getBatteryDetails'     : true,
        'refreshBatteryWarranty': false,
        'replaceBatteryExternal': false,
        'replaceBatteryTalron'  : true,
    },
    '6'      : {
        'getBatteryDetails'     : true,
        'refreshBatteryWarranty': false,
        'replaceBatteryExternal': true,
        'replaceBatteryTalron'  : true,
    },
};
if (config.talron.useDummyBattery) {
    initialBatteryState = {
        hasBattery     : '1',
        type           : 'talron.battery.types.1',
        model          : 'some-model',
        serialNumber   : '12345678901234-67890',
        manufacturer   : 'VERTA',
        location       : 'אבי מצברים',
        isHeatSignature: true,
        warranty       : {
            fromTime          : 1620295915,
            toTime            : 1651831915,
            fromDate          : '05/2021',
            toDate            : '05/2022',
            isExpired         : true,
            wasRefreshed      : false,
            wasRefreshedOnTime: 0,
        }
    };
} else {
    initialBatteryState = {
        hasBattery     : false,
        type           : null,
        serialNumber   : null,
        model          : null,
        manufacturer   : null,
        location       : null,
        isHeatSignature: false,
        warranty       : {
            fromTime          : null,
            toTime            : null,
            fromDate          : null,
            toDate            : null,
            isExpired         : null,
            wasRefreshed      : null,
            wasRefreshedOnTime: 0,
        },
        
        
    };
}

export default {
    state    : () => {
        let state = {
            contactNumberReadable: '1-700-700-130',
            contactNumber        : '1700700130',
            ui                   : {
                showHeader    : true,
                showFooter    : true,
                appActionState: 'default', // please use type enum ['default','success','error']
            },
            user                 : {
                name       : '',
                accountName: '',
                personalId : false,
                id         : false,
                accountId  : false,
                permissions: [],
            },
            battery              : {...initialBatteryState},
            replacementGUID      : config.talron.useDummyGUID ? '123456789-123456789-123456789-12' : false,
            nav                  : {
                side  : {},
                footer: {},
            }
        };
        
        if ( ! config.talron.rememberUserWithCookie) {
            return state;
        }
        
        // TODO;
        let cookieData = cookie.get(userCookieName);
        try {
            cookieData = JSON.parse(cookieData);
        } catch (e) {
            return state;
        }
        
        if ( ! cookieData) {
            return state;
        }
        
        //  check cookie last activity - do not apply expired session
        let now        = (new Date()).getTime();
        let expiryTime = cookieData.lastActivity + (config.talron.sessionLifeTime * 1000);
        
        if ( ! cookieData.lastActivity || expiryTime < now) { // do not apply invalid session
            return state;
        }
        
        // add the user state
        state.user = {...state.user, ...cookieData}
        
        return state;
    },
    mutations: {
        /*
         showGlobalSpinner (state, payload) {
         // if null like, or excactly true - show spinner with default text
         if (    typeof payload === 'undefined'                     ||
         ( typeof payload === 'object' && payload === null )  ||
         payload === true                                       ) {
         state.globalSpinner.text = defaults.globalSpinnerText;
         state.globalSpinner.active = true;
         return;
         }
         
         if (typeof payload === 'boolean' && payload === false) {
         state.globalSpinner.text   = defaults.globalSpinnerText;
         state.globalSpinner.active = false;
         return;
         }
         
         // if string - show the spinner with string
         if (typeof payload === "string") {
         state.globalSpinner.text   = payload;
         state.globalSpinner.active = true;
         return;
         }
         
         // deeper customization via object
         if (typeof payload === "object" && payload !== null) {
         if (payload.text) {
         state.globalSpinner.text   = payload.text;
         }
         
         if (payload.active) {
         state.globalSpinner.active   = payload.active;
         }
         return;
         }
         
         debug('commiting to ui showGlobalSpinner with an unsupported payload', 2, payload);
         // return state.globalSpinner
         },
         */
        showHeader(state, payload) {
            state.ui.showHeader = Boolean(payload);
        },
        showFooter(state, payload) {
            state.ui.showFooter = Boolean(payload);
        },
        appActionState(state, payload) {
            if ( ! ['default', 'success', 'error'].includes(payload)) {
                utilities.debug('can not set app action state to given value @store talron addActionState(). supported values are "default", "error" and "success"', 2, payload);
                return;
            }
            
            state.ui.appActionState = payload;
            
        },
        resetAppActionState(state, payload) {
            state.ui.appActionState = 'default';
        },
        setUser(state, payload, c, d) {
            if ( ! payload || typeof payload !== 'object') {
                utilities.debug('can not set talron user profile, invalid input', 2, payload);
                return;
            }
            state.user.name        = payload?.name ? payload.name : state.user.name;
            state.user.accountName = payload?.accountName ? payload.accountName : state.user.accountName;
            state.user.personalId  = payload?.personalId ? payload.personalId : state.user.personalId;
            state.user.id          = payload?.id ? payload.id : state.user.id;
            state.user.accountId   = payload?.accountId ? payload.accountId : state.user.accountId;
            state.user.permissions = payload?.permissions ? payload.permissions : state.user.permissions;
            if (config.talron.rememberUserWithCookie) {
                this.commit('talron/userStateCookieWithLastActivity');
            }
        },
        setUserName(state, payload) {
            state.user.name = payload;
            
            if (config.talron.rememberUserWithCookie) {
                this.commit('talron/userStateCookieWithLastActivity');
            }
        },
        setUserPersonalId(state, payload) {
            state.user.personalId = payload;
            
            if (config.talron.rememberUserWithCookie) {
                this.commit('talron/userStateCookieWithLastActivity');
            }
        },
        setUserId(state, payload) {
            state.user.id = payload;
            
            if (config.talron.rememberUserWithCookie) {
                this.commit('talron/userStateCookieWithLastActivity');
            }
        },
        resetUser(state, payload) {
            state.user.name        = '';
            state.user.accountName = '';
            state.user.personalId  = false;
            state.user.id          = false;
            state.user.accountId   = false;
            if (config.talron.rememberUserWithCookie) {
                this.commit('talron/userStateCookieWithLastActivity');
            }
        },
        userStateCookieWithLastActivity: (state, time) => {
            if (typeof time === 'undefined') {
                time = (new Date()).getTime();
            }
            cookie.set(userCookieName, {...state.user, lastActivity: time}, {expires: undefined});
        },
        userStateToCookie(state) {
            cookie.set(userCookieName, {...state.user}, {expires: undefined});
        },
        setBattery(state, payload) {
            state.battery            = {...payload};
            state.battery.hasBattery = true;
        },
        setBatteryWarrantyFields(state, payload) {
            if ( ! state.battery.warranty) {
                return false;
            }
            
            state.battery.warranty = {...state.battery.warranty, ...payload}
            
        },
        replacementGUID(state, paylod) {
            state.replacementGUID = paylod;
        },
        clearReplacementGUID(state, paylod) {
            state.replacementGUID = false;
        },
        setAllNav(state, payload) {
            state.nav = payload;
        },
        
        
    },
    actions  : {
        logoutTalronUser(context) {
            context.commit('resetUser')
        }
    },
    getters  : {
        showHeader(state) {
            return state.ui.showHeader;
        },
        showFooter(state) {
            return state.ui.showFooter;
        },
        appActionState(state) {
            return state.ui.appActionState;
        },
        userName(state) {
            return state.user.name;
        },
        userAccountName(state) {
            return state.user.accountName;
        },
        hasUser(state) {
            return state.user.id !== false && state.user.id !== null && state.user.id !== '' && state.user.id !== 0 && state.user.id !== '0';
        },
        user(state) {
            return state.user;
        },
        userPersonalId(state) {
            return state.user.personalId;
        },
        userId(state) {
            return state.user.id;
        },
        userAccountId(state) {
            return state.user.accountId;
        },
        hasTalronUser(state) {
            return !! state.user.personalId;
        },
        battery(state) {
            return state.battery;
        },
        hasBattery(state) {
            return state.battery.hasBattery;
        },
        isBatteryWarrantyValid(state) {
            return state.battery.hasBattery && state.battery.warranty.toTime > Math.floor(Date.now() / 1000);
        },
        isBatteryWithHeatSignature(state) {
            return !! state.battery.isHeatSignature;
        },
        contactNumber(state) {
            return state.contactNumber;
        },
        contactNumberReadable(state) {
            return state.contactNumberReadable;
        },
        wasBatteryRefreshed(state) {
            return state.battery.warranty.wasRefreshed
        },
        batteryRefreshedOnTime(state) {
            return state.battery.warranty.wasRefreshedOnTime
        },
        replacementGUID(state) {
            return state.replacementGUID;
        },
        rawUserPermissions(state) {
            if ( ! state.user.permissions) {
                return [];
            }
            
            if ( ! Array.isArray(state.user.permissions)) {
                return [];
            }
            return state.user.permissions;
        },
        userPermissions(state, getters) {
         
            let raw    = getters['rawUserPermissions'];
            let result = {...permissionMap.default};
         
            if ( ! raw) {
                return result;
            }
            
            for (const accessLevel of raw) {
                let mappedAccessLevel = permissionMap[String(accessLevel)];
                
                if ( typeof mappedAccessLevel === 'undefined') {
                    continue;
                }
                
                for (const [key, value] of Object.entries(mappedAccessLevel)) {
                    if (value === true) {
                        result[key] = value;
                    }
                   
                }
            }
            
            // special rules
            //1. batteries with heat signature can not be replaced
            if (getters['hasBattery'] && getters['battery'].isHeatSignature) {
                result['refreshBatteryWarranty'] = false;
            }
            
            return result;
        },
        userCan  : (state, getters) => (action) => {
            let permissions = getters['userPermissions'];
            return permissions && permissions[action] && permissions[action] === true;
        },
        nav      : (state, getters) => {
            return state.nav;
        },
        sideNav  : (state, getters) => {
            return (state.nav && state.nav.side) ? state.nav.side : {};
        },
        footerNav: (state, getters) => {
            return (state.nav && state.nav.footer) ? state.nav.footer : {};
        },
        /*
         isGlobalSpinnerActive(state) {
         return state.globalSpinner.active;
         },
         */
        
    }
};
