module.exports =  {
    name: 'טלרון',
    iconPaths: {
        favicon32: 'img/talron-icons/favicon-32x32.png',
        favicon16: 'img/talron-icons/favicon-16x16.png',
        appleTouchIcon: 'img/talron-icons/apple-icon-152x152.png',
        maskIcon: 'img/talron-icons/safari-pinned-tab.svg',
        msTileImage: 'img/talron-icons/ms-icon-144x144.png'
    },
    themeColor: '#0284bb',
}
