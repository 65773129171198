
export default {
    $validator: (value) => {
        // only string
        if (typeof value !== 'string') {
            return false;
        }
        
        // max length
        if (value.length > 13) {
            return false;
        }
    
        // min length
        if (value.length < 10) {
            return false;
        }
        const regExp = /[a-zA-Z]/g;
      
        if(regExp.test(value)){
            if ( ! value.startsWith('SU')) {
                console.log('validation failed: battery code has letters and doesnt start with SU')
                return false;
            }
        }
        // if it has any letters, it must start with "SU"

        // only safe valid charecters
    //    let regex = new RegExp(/^[\w\-\s]+$/);
   //     let result =  regex.test(value);

        return true;
    },
};
