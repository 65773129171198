import uikit from 'uikit';
import CustomIcons from 'uikit/dist/js/uikit-icons-custom';
uikit.use(CustomIcons);



// customize uikit js.
import {$, addClass, assign, css, Deferred, hasClass, height, html, isString, on, Promise, removeClass} from 'uikit/src/js/util/index';


// todo: add more options?
let modal = uikit.modal;
// original helper function copied from uikit modal component
function openDialog(tmpl, options, hideFn, submitFn) {

    options = assign({bgClose: false, escClose: true, labels: modal.labels}, options);

    const dialog = modal.dialog(tmpl(options), options);
    const deferred = new Deferred();

    let resolved = false;

    on(dialog.$el, 'submit', 'form', e => {
        e.preventDefault();
        deferred.resolve(submitFn && submitFn(dialog));
        resolved = true;
        dialog.hide();
    });

    on(dialog.$el, 'hide', () => !resolved && hideFn(deferred));

    deferred.promise.dialog = dialog;

    return deferred.promise;
}

// our custom modal header html getter
let getModalHeaderHtml = (title) => {
    if ( typeof title !== 'string' || !title) {
        return '';
    } else {
        return ` <div class="uk-modal-header"><h2>${isString(title) ? title : html(title)}</h2></div>`;
    }
};


// add title option to alert, confirm, and prompt
modal.alert = function (message, options) {
    return openDialog(
        ({labels, title}) => {return getModalHeaderHtml(title) +
            `<div class="uk-modal-body">${isString(message) ? message : html(message)}</div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-primary uk-modal-close" autofocus>${labels.ok}</button>
            </div>
        `},
        options,
        deferred => deferred.resolve()
    );
};

modal.confirm = function (message, options) {
    return openDialog(
        ({labels, title}) => {return getModalHeaderHtml(title) +
            `<form>
                <div class="uk-modal-body">${isString(message) ? message : html(message)}</div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">${labels.cancel}</button>
                    <button class="uk-button uk-button-primary" autofocus>${labels.ok}</button>
                </div>
            </form>`},
        options,
        deferred => deferred.reject()
    );
};

modal.prompt = function (message, value, options) {
    return openDialog(
        ({labels, title}) => {return getModalHeaderHtml(title) +
            `<form class="uk-form-stacked">
                <div class="uk-modal-body">
                    <label>${isString(message) ? message : html(message)}</label>
                    <input class="uk-input" value="${value || ''}" autofocus>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">${labels.cancel}</button>
                    <button class="uk-button uk-button-primary">${labels.ok}</button>
                </div>
            </form>`},
        options,
        deferred => deferred.resolve(null),
        dialog => $('input', dialog.$el).value
    );
};

modal.labels = {
    ok: 'Ok',
    cancel: 'Cancel'
};

export default uikit;
