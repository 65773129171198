export default (router) => {
    return {
        routerMethod: 'saffronBus',
        handler: async (to, from, context) => {
            if ( ! router.$store.getters['talron/hasBattery']) {
                return {name: 'index'};
            }
            return true;
        }
    }
}
