export default {
    rememberUserWithCookie: true,
    useDummyUserPersonalId: false,
    useDummyBattery: false,
    useDummyGUID: false,
    validateScannedBarcodes: true,
    otpLifetime: 120,
    sessionLifeTime: 10 * 60 * 60, //seconds
    scanditApiKey: 'AU7yJTrUGFFCH8IGVh6dCNsB24iwEyC6rh3gXHNEnEEGNaDXZFT7BzpxsUFZbUrHylcGHTRDArXxb71wlgTX1aZ+CGH1UuJoona7K193NTfnEqfzIg9S3etDi9jJPDljGO2kABFpHPMvnLD3TVv2UDUH5Zefk25+AHVQWL9VSlydCMmlY5WkVN0Yx9LP9lnDmTfmTLf47FDC3D4dlyA1lkmSUuoPYj678YurofJvSQACldwfBz2cQUUboEqMqAGSCL4AuXCA0DNeyQiQq/DFKVvP5YiLGAmAFxyiYpV1qsIZKCo9j1qHMjvAU7zEtl7+KPebxm0s3AMKpPtlV98s5i8PSyy3mLahhvKxZ2eIn3mA+zNvFVsmzK5wEletQAYE2HJ9f15rsHEJQWrx/qUWJZcrHxEZ/HBFbx/uvESjw/3wRakn85OGOdsjHVnVcoXFOfLaJ93Q2+5WpCDzCFbJGWJqesBpwslQmgkd+XoxVry58CYkR9Gfqi51lds/szGyAh97M6+G2zLtnsk7rv5deekpQEYldYYWCR6Tawl+QymcNnYqXM9wETTXK7c+b7HUvw4+eB8Orex+1YTstcE7RSh2tt+9lkJEqaRBPDAZBAwIeyT7qEIcYA97+s4fY/3U21qCaNNfpCyqXAaknHpYnfhVnLwyXo07Ya/mm8OEG9AYyn8mNN6Q2uY+guEp3EGeM997bACK3LoqkdiXP7hj6WAe176FepMiSS40caWBGms//HRKmcn01gdOUMlxLV4EiLcyiSsQiD/oOGeR/uUIOxFONgWC24P2bHyZfba6+w==',
   // scanditApiKey: 'AQeCpSbUBF1dQp37ZxMc7htFawpGLLav5DrK/C5HYdcKAbzmlXFKtyV13hB6fPO0dSRPJGV+joPacqmv0AFHaEEE2Bapbn63byjwsoVVsO//ZeV9IlkrtWJ554WTZvmmkSt2v+gzeahwYb1CPVU0hLZXcl+7U95Cr2RH//Z1tfclafa5oUvyFChTSna/bG2dJWDyJOUsus/eb68ChkPKflVSyHvxRoW+k2pUABVbJ5mkftjGiy5v99d3tx76SA+nk3CdwSJSJ6sWQQqE5U1X8+RTk12GRIkjTlgBAh52ad8heHug4EadO69YgcfrfDtY61/ZQxBj8n7mbKoJ92VqW7VPA00hfypdeWGBCZtcjJ0Qe6Dy1CzK1clOyOuYeFOuP1kVtXZQmpWTdw7yUAcEPZZF4f2JcDB4lkgwZGNbMEhobw2ucWvLU7xcOK5Qcy0mmWv/mFx8l8jSakukPU/7+hRRmDt7ZYZy+FosCIBuer1AQ61NPiwqQm9X39s0YcE1MTJx/d0HLSGcRa3xHAnclnYRsud8eztNq4fRGj6fVakLG3MLa1lmZr8+X5t5Y8LcFIcMfz1So+f5h0fGP8ajR4YxCE9p/RgCLQ5NbOQyNudvK4KshFbPBZFZCBdEq1h8nW56Zbpw+eLe9GtlT5DvJ2KHQRBZlAzLBORF5TcpOG9T08hitpB/fHjD6ST0Av5DR6CCV7aCGAdeXChSuj8hMY6i5kCKhBsZOUY9DvTN+rW11f0+Xp/p/R2gi0XLIfOjxOtzQGzGaZlGwnKQvSwwId2NREfQ6P9OZDByNCCARIrvbIhsvzP2oKuxthwXMb0NHCrbyBgJ3O7oTM7/6qJhSKbK7B3n2n44of3yiRHiRM6fSxM6twMbRK2gWcxnmHh2wqSuEf/kuFyRXOmB4gJ0nTyeXOnnpdp8rKbgTk5qBEMAQnAGbbQ5VyNh0l8KNcUzeRnaVteTP/H/gOPSbRjqG04bg97p7jRpbJW03IzMnXruPPq33qa87gu84HSvoqB2f2m7gdHjnfg1457PCIaFbdq3n/qPGASJ1+LpKuknjTfomCwbd1b3wWSXhQ99KerlRjbUyINozjdRER7IUUdz+gVWiyHHubBsO4D4e8RpFvYN6OCaRzsAPf53q5fx4gAXSz7F1B35zJm5qqszERSb1SYl24zGeH0uIl1wRToC68+1/qPGdObS7okOGKWS7cNd'
//'AQeCpSbUBF1dQp37ZxMc7htFawpGLLav5DrK/C5HYdcKAbzmlXFKtyV13hB6fPO0dSRPJGV+joPacqmv0AFHaEEE2Bapbn63byjwsoVVsO//ZeV9IlkrtWJ554WTZvmmkSt2v+gzeahwYb1CPVU0hLZXcl+7U95Cr2RH//Z1tfclafa5oUvyFChTSna/bG2dJWDyJOUsus/eb68ChkPKflVSyHvxRoW+k2pUABVbJ5mkftjGiy5v99d3tx76SA+nk3CdwSJSJ6sWQQqE5U1X8+RTk12GRIkjTlgBAh52ad8heHug4EadO69YgcfrfDtY61/ZQxBj8n7mbKoJ92VqW7VPA00hfypdeWGBCZtcjJ0Qe6Dy1CzK1clOyOuYeFOuP1kVtXZQmpWTdw7yUAcEPZZF4f2JcDB4lkgwZGNbMEhobw2ucWvLU7xcOK5Qcy0mmWv/mFx8l8jSakukPU/7+hRRmDt7ZYZy+FosCIBuer1AQ61NPiwqQm9X39s0YcE1MTJx/d0HLSGcRa3xHAnclnYRsud8eztNq4fRGj6fVakLG3MLa1lmZr8+X5t5Y8LcFIcMfz1So+f5h0fGP8ajR4YxCE9p/RgCLQ5NbOQyNudvK4KshFbPBZFZCBdEq1h8nW56Zbpw+eLe9GtlT5DvJ2KHQRBZlAzLBORF5TcpOG9T08hitpB/fHjD6ST0Av5DR6CCV7aCGAdeXChSuj8hMY6i5kCKhBsZOUY9DvTN+rW11f0+Xp/p/R2gi0XLIfOjxOtzQGzGaZlGwnKQvSwwId2NREfQ6P9OZDByNCCARIrvbIhsvzP2oKuxthwXMb0NHCrbyBgJ3O7oTM7/6qJhSKbK7B3n2n44of3yiRHiRM6fSxM6twMbRK2gWcxnmHh2wqSuEf/kuFyRXOmB4gJ0nTyeXOnnpdp8rKbgTk5qBEMAQnAGbbQ5VyNh0l8KNcUzeRnaVteTP/H/gOPSbRjqG04bg97p7jRpbJW03IzMnXruPPq33qa87gu84HSvoqB2f2m7gdHjnfg1457PCIaFbdq3n/qPGASJ1+LpKuknjTfomCwbd1b3wWSXhQ99KerlRjbUyINozjdRER7IUUdz+gVWiyHHubBsO4D4e8RpFvYN6OCaRzsAPf53q5fx4gAXSz7F1B35zJm5qqszERSb1SYl24zGeH0uIl1wRToC68+1/qPGdObS7okOGKWS7cNd'
}
