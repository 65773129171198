export default {
    frameworks          : ['uikit'],
    frameworkComponents : ['uikit'],
    scss                : ['styles'],
    responsive : {
        classMap : {
            xsmall  : 400,
            small   : 640,
            medium  : 960,
            large   : 1200,
            xlarge  : 1600,
        },
        responsiveClassPrefix : 'break-',
        responsiveClassMode: 'mobileFirst',
    }
}
