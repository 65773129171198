export default {
    tag: 'he-IL',
    label: 'HE',
    labelFull: 'Hebrew (Israel)',
    flag: 'israel',
    messages: {
        message: {}
    },
    datetimeFormats : {
        short: {
            year: 'numeric', month: '2-digit', day: 'numeric',
        },
        long: {
            year: 'numeric', month: 'long', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        },
        full : {
            year: 'numeric', month: 'long', day: 'numeric',
            weekday: 'long', hour: 'numeric', minute: 'numeric', timeZoneName: 'short'
        }
    },
    numberFormats: {
        currency: {
            style: 'currency', currency: 'USD', notation: 'standard', currencyDisplay: 'symbol'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
    useTitleCase: true,
    translateNumber (instance, value, type, local, options) {
        return instance.$n(value, type, local, options);
    }
}
